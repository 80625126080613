/**
 *
 * SignupPage
 *
 */

import { Dispatch, compose } from 'redux';
import React, { Fragment } from 'react';
import { checkEmailSubmit, resetState as resetStateActions, signupSubmit } from './actions';

import { Helmet } from 'react-helmet';
import { RootState } from './types';
import SignupForm, { CustomerInfo } from 'components/SignupForm/SignupForm';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import { push } from 'connected-react-router';
import reducer from './reducer';
import saga from './saga';
import selectSignupPage from './selectors';
import translations from 'translations';
import { RouteProps } from 'react-router-dom';

interface OwnProps extends RouteProps {
  signuppage: any;
}

interface StateProps {}

interface DispatchProps {
  onSubmitSignup: (data: any) => void;
  onCheckEmail: (data: any) => void;
  goToSignIn: () => void;
  resetState: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class SignupPage extends React.PureComponent<Props> {
  public componentWillUnmount() {
    this.props.resetState();
  }

  public render() {
    const {
      signuppage: { isCheckingEmail, validationEmailMessage, listSalesPIC, loading },
      location,
    } = this.props;
    const query = location && (new URLSearchParams(location.search) as any);
    const customerInfo: CustomerInfo = {
      userName: query.get('userName'),
      email: query.get('email'),
      companyName: query.get('companyName'),
      phoneNumber: query.get('phoneNumber'),
      provinceId: query.get('provinceId'),
      districtId: query.get('districtId'),
      wardId: query.get('wardId'),
      roleAtBusiness: query.get('roleAtBusiness'),
      addressNo: query.get('addressNo'),
      acquisitionSource: query.get('acquisitionSource'),
      buyerCustomerContactRequestId: query.get('buyerCustomerContactRequestId'),
      createdBy: query.get('createdBy')
    };
    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        <SignupForm
          onSubmit={this.props.onSubmitSignup}
          onCheckEmail={this.props.onCheckEmail}
          isCheckingEmail={isCheckingEmail}
          validationEmailMessage={validationEmailMessage}
          customerInfo={customerInfo}
          listSalesPIC={listSalesPIC}
          loading={loading}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  signuppage: selectSignupPage(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onSubmitSignup: (data: any) => dispatch(signupSubmit(data)),
    onCheckEmail: (data: any) => dispatch(checkEmailSubmit(data)),
    goToSignIn: () => dispatch(push('/signIn')),
    resetState: () => dispatch(resetStateActions()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'signupPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'signupPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(SignupPage);
